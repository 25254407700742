<!-- 
    页面 人脸下发详情-设备 (这里的Table 用了 elemnt-ui)
-->
<template>
    <div id="face-detail-page" class="data-list-container dark-layout">
      <vx-card  class="mb-5">
       <el-descriptions title="设备基础信息">
            <el-descriptions-item label="设备名称">{{deviceInfo.device_name}}</el-descriptions-item>
            <el-descriptions-item label="设备类型">{{deviceInfo.product_model}}</el-descriptions-item>
            <el-descriptions-item label="设备编号">{{deviceInfo.device_code}}</el-descriptions-item>
            <el-descriptions-item label="点位名称">{{deviceInfo.space_name}}</el-descriptions-item>
            <el-descriptions-item label="状态">
                <template>
                  <el-tag size="mini" v-if="deviceInfo.run_mode == 0" type="warning">离线</el-tag>
                  <el-tag size="mini" v-if="deviceInfo.run_mode == 1" type="success">在线</el-tag>
                  <el-tag size="mini" v-if="deviceInfo.run_mode == 2" type="warning">故障</el-tag>
                  <el-tag size="mini" v-if="deviceInfo.run_mode == 3" type="danger">维保</el-tag>
                </template>
            </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="1">
            <el-descriptions-item label="下发状态">{{deviceInfo.下发总状态}}</el-descriptions-item>
            <el-descriptions-item label="下发详情">{{deviceInfo.下发详情}}</el-descriptions-item>
        </el-descriptions>
      </vx-card>

      <vx-card>
          <div class="table_height_container">
            <el-row class="search-row mb-5" type="flex">
              <el-col :span="12">
                <el-input class="search-input" size="mini" v-model="selectOption.name" placeholder="请输入姓名"></el-input>
                <el-button class="search-btn" size="mini"  @click="searchAction">搜索</el-button>
              </el-col>
              <el-col :span="12" style="text-align: right;">
                    <el-dropdown  @command="changeSyncStatus">
                      <span class="el-dropdown-link">
                        <span class="inline-block mr-2" style="font-weight: bold; font-size: 12px;">下发状态:</span>
                        <el-tag size="mini" v-if="sync_status == 1" type="success">成功</el-tag>
                        <el-tag size="mini" v-if="sync_status == 2" type="danger">失败</el-tag>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">成功</el-dropdown-item>
                        <el-dropdown-item command="2">失败</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
            </el-row>
            <el-row>
              <el-col v-if="facelist.length==0"  style="text-align: center;">
                  <span>暂无数据</span>
              </el-col>
              <el-col v-else :span="4" v-for="(item, index) in facelist" :key="index">
                <el-card class="mb-2 mr-2" :body-style="{ padding: '0px'}">
                  <el-carousel trigger="click" height="150px" :autoplay="false">
                    <el-carousel-item>
                      <el-image style="width: 100%; height: 150px" :src="prefixHost + item.head_pic" fit="fill">
                        <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                      </el-image>
                    </el-carousel-item>
                    <el-carousel-item>
                      <el-image style="width: 100%; height: 150px" :src="prefixHost + item.live_pic" fit="fill">
                        <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                      </el-image>
                    </el-carousel-item>
                  </el-carousel>
                  <div style="padding: 14px; line-height:2">
                    <p style="font-size:12px">用户名: <span>{{ item.people_name }}</span></p>
                    <p :title="item.certificate_no" style="font-size:12px" class="id-text">身份证号: <span>{{ item.certificate_no }}</span></p>
                    <p :title="item.headSync ? item.headSync.head_sync_status : '无'" style="font-size:12px" class="id-text">人脸照片: 
                      <el-tag :type="(item.headSync.head_msg).includes('失败') ? 'danger': 'success' " size="mini" >{{ item.headSync ? item.headSync.head_msg : "未下发" }} </el-tag>
                      <span> ({{ item.headSync ? item.headSync.head_sync_status : "无" }})</span>
                    </p>
                    <p :title="item.liveSync ? item.liveSync.live_sync_status : '无'" style="font-size:12px" class="id-text">生活照片: 
                      <el-tag :type="(item.headSync.head_msg).includes('失败') ? 'danger': 'success' " size="mini" >{{ item.liveSync ? item.liveSync.live_msg : "未下发" }} </el-tag>
                      <span> ({{ item.liveSync ? item.liveSync.live_sync_status : "无" }})</span>
                    </p>
                    <!-- <div class="bottom clearfix">
                      <el-button type="text" size="small" class="primary">删除人脸</el-button>
                      <el-button type="text" size="small" class="primary">再次下发</el-button>
                    </div> -->
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
      </vx-card>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'
export default {
  data() {
    return {
      // 图片前缀
      prefixHost: this.$cFun.getUrlPrefix(),
      // 默认状态
      sync_status: "1",
      // 搜索条件
      selectOption: {
       name: ""
      },
      // 渲染数据
      facelist: [],
      deviceInfo: {
        device_code: "",
        device_name: "",
        device_type: "",
        product_model: "",
        run_mode: "",
        space_name: "",
        下发总状态: "",
      },
      // 当前页
      pageOpt: {
        currentPage: 1,
        currentPageSize: 10,
        totalPageSize: 0,
        hideSinglePage: true,
        pageSizes: [10, 20, 30],
        pageCount: 5
      }
    };
  },
  watch: {
    "$store.state.auth.space.space_code"(val) {
      this.$router.push({ name : 'faceList'})
    },
  },
  methods: {
    // 状态切换
    changeSyncStatus(val) {
      this.sync_status = val
      this.pageOpt.currentPage = 1;
      this.getFacelist(this.sync_status)
      this.getFaceCount(this.sync_status)
    },
    // 请求接口
    async searchAction(){
      this.pageOpt.currentPage = 1;
      this.getFacelist(this.sync_status)
      this.getFaceCount(this.sync_status)
    },
    // 获取单个设备人脸数量 
    // sync_status  下发状态 1 成功 2 失败
    async getFaceCount(sync_status) {
      try {
        let params = {
          "device_code": this.deviceInfo.device_code,
          "name": this.selectOption.name,
          "sync_status": sync_status
        }
        let result = await this.$apis.ODC("获取单个设备人脸数量", params);
        if (this.$apis.isOk(result)) {
          this.pageOpt.totalPageSize = result.facecount;
        } else {
           this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 获取单个设备人脸列表
    async getFacelist(sync_status) {
      try {
        let params = {
          "device_code": this.deviceInfo.device_code,
          "name": this.selectOption.name,
          "sync_status": sync_status,
          "index": this.pageOpt.currentPage,
          "pagesize":  this.pageOpt.currentPageSize
        }
        let result = await this.$apis.ODC("获取单个设备人脸列表", params);
        if (this.$apis.isOk(result)) {
          this.facelist = result.facelist
        } else {
           this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
         this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    }
  },
  async created() {
    // 请求数据
    try {
      this.selectOption.name = this.$route.query.people_name 
      this.deviceInfo = JSON.parse(Base64.decode(this.$route.query.deviceInfo))
    } catch (e) {
      this.$router.push({ name : 'faceList'})
    }
    this.getFacelist(this.sync_status)
    this.getFaceCount(this.sync_status)
  },
  components: {
  }
};
</script>

<style lang="scss" scope="scope">
  #face-detail-page {
    .search-row {
      margin-bottom: 5px;
      .search-select {
        margin-right: 5px;
      }
      .search-input {
        width: 200px;
        margin-right: 5px;
      }
      .search-btn{
        &:hover {
          background-color: rgba(var(--vs-primary), 0.1);
          border-color: rgba(var(--vs-primary), 0.1);
        }
      }
    }

    .id-text {
      overflow:hidden; //超出的文本隐藏
      text-overflow:ellipsis; //用省略号显示
      white-space:nowrap; //不换行
    }

    .image-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: #f5f7fa;
      color: #909399;
      font-size: 30px;
    }

    .time {
      font-size: 13px;
      color: #999;
    }
    
    .bottom {
      margin-top: 13px;
      line-height: 12px;
    }

    .button {
      padding: 0;
      float: right;
    }

    .image {
      width: 100%;
      display: block;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    
    .clearfix:after {
        clear: both
    }
  }
</style>